/* eslint-disable no-unused-vars */
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_USER,
  CLEAR_USER,
  SET_ROLE_ID,
} from "../actions/types";

const initialState = {
  user: {
    id: "",
    role: "",
    email: "",
  },

  isLoggedIn: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        // user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };

    case CLEAR_USER:
      return initialState;
    case SET_ROLE_ID:
      return {
        ...state,
        roleId: action.payload,
      };
    default:
      return state;
  }
}
