import {
  TRACKCHANGES_DOWNLOAD_REQUEST,
  TRACKCHANGES_FAILURE,
  TRACKCHANGES_REQUEST,
  TRACKCHANGES_SUCCESS,
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  hasMore: true,
  changeLogList: [],
  downloadData: null,
};

const TrackChangeReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TRACKCHANGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TRACKCHANGES_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TRACKCHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        changeLogList: action.payload.rows,
        hasMore: action.payload?.count > state.changeLogList.length,
      };
    case TRACKCHANGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default TrackChangeReducer;
