/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

// import { logout } from "../../../redux/actions/auth";
//import event bus
import EventBus from "../../../common/EventBus";
import { clearUser } from "../../../redux/actions/auth";
import { logoutAction } from "../../../redux/services/auth.service";
import { clearSession } from "../../../redux/actions/session";

const UserBox = (props) => {
  const dispatch = useDispatch();

  const authDetails = props.authDetails;

  const logOut = async () => {
    await logoutAction();
    dispatch(clearSession());
    dispatch(clearUser());
  };
  useEffect(() => {
    if (
      authDetails &&
      authDetails.expiry &&
      new Date().getTime() > authDetails.expiry
    )
      logOut();
  }, [authDetails]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  return (
    <>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <Button
                className="btn-pill btn-shadow btn-shine"
                color="focus"
                onClick={logOut}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBox;
