/* eslint-disable no-case-declarations */
import {
  ADD_TRANSCRIPTREQUEST_LOCAL,
  CLEARTRANSCRIPT,
  DELETE_COMBINED_TRANSCRIPT,
  EXTRACTALLTRANSCRIPT,
  MAILBOX_REQUEST,
  RETRIEVE_TRANSCRIPT_FAILURE,
  RETRIEVE_TRANSCRIPT_REQUEST,
  RETRIEVE_TRANSCRIPT_SUCCESS,
  UPDATE_TRANSCRIPT,
} from "../actions/types";

const initialState = {
  totalCount: 0,
  tranScriptDetails: [],
  createdTranScript: {},
  selectedTranScript: {},
  loading: false,
  error: null,
  hasMore: true,
};

const transcriptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_TRANSCRIPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case RETRIEVE_TRANSCRIPT_SUCCESS:
      // Handles retrieving transcript requests
      return {
        ...state,
        totalCount: payload?.transcriptDetails?.count,
        tranScriptDetails:
          payload.transcriptDetails.rows.length > 0
            ? payload.transcriptDetails.rows
            : [],
        loading: false,
        hasMore: payload?.count > state.tranScriptDetails.length,
      };
    case RETRIEVE_TRANSCRIPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_TRANSCRIPTREQUEST_LOCAL:
      // Handles adding local transcript requests
      // Filters out existing transcript details and adds new ones
      // eslint-disable-next-line no-case-declarations
      const newItems = payload.filter(
        (item) =>
          !state.tranScriptDetails.some(
            (transcript) =>
              (item.formNumber
                ? transcript.taxForm === item.formNumber
                : transcript.taxForm === item.taxForm) &&
              transcript.taxYear === item.taxYear &&
              transcript.productType === item.productType &&
              transcript.purposeType === item.purposeType &&
              transcript.taxFormTypeId === item.taxFormTypeId &&
              Number(transcript.taxPeriod) === Number(item.taxPeriod)
          )
      );

      return {
        ...state,
        totalCount: state.totalCount + newItems.length,
        selectedTranScript: payload,
        tranScriptDetails: [...newItems, ...state.tranScriptDetails],
      };

    case EXTRACTALLTRANSCRIPT:
      // Handles extracting all transcript requests
      return {
        ...state,
      };

    case CLEARTRANSCRIPT:
      // Handles clearing transcript details
      return {
        ...state,
        tranScriptDetails: [],
      };

    case MAILBOX_REQUEST:
      // Handles mailbox requests
      return {
        ...state,
      };

    case DELETE_COMBINED_TRANSCRIPT:
      // Handles deleting combined transcript requests
      return {
        ...state,
      };

    case UPDATE_TRANSCRIPT: {
      const {
        formNumber,
        taxFormTypeId,
        taxYear,
        taxPeriod,
        status,
        message,
        lastChecked,
        id,
        ids,
        firstName,
        lastName,
        middleInitial,
        legalBusinessName,
      } = payload;

      return {
        ...state,
        tranScriptDetails: state.tranScriptDetails.map((transcript) => {
          if (ids) {
            // Handle the case where id is present in the payload
            if (transcript.id === ids) {
              return {
                ...transcript,
                status,
                message,
                lastChecked,
                id,
                Account: {
                  firstName: firstName,
                  lastName: lastName,
                  middleInitial: middleInitial,
                  legalBusinessName: legalBusinessName,
                },
              };
            }
          } else {
            // Handle the case where id is not present in the payload
            if (
              transcript.formNumber === formNumber &&
              transcript.taxFormTypeId === taxFormTypeId &&
              transcript.taxYear === taxYear &&
              transcript.taxPeriod === taxPeriod
            ) {
              return {
                ...transcript,
                status,
                message,
                lastChecked,
                id,
                Account: {
                  firstName: firstName,
                  lastName: lastName,
                  middleInitial: middleInitial,
                  legalBusinessName: legalBusinessName,
                },
              };
            }
          }
          return transcript; // Return the transcript unchanged if conditions are not met
        }),
      };
    }

    default:
      return state;
  }
};

export default transcriptReducer;
