// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table tr td {
  border: 1px solid gray;
}

.table_wrapper {
  max-height: 500px; /* Set your desired fixed height */
  overflow-y: auto;
  /* Custom scrollbar styles */
}
.table_wrapper::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
}
.table_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}
.table_wrapper::-webkit-scrollbar-thumb {
  background: #6c0cdc; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}
.table_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.table_container {
  width: 100%;
  border-collapse: separate;
  border: 1px solid gray;
}

.table_container thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.fade-bg {
  height: 100%;
  width: 100%;
  background-color: rgba(241, 241, 241, 0.8196078431);
  position: absolute;
  z-index: 9999;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
}

.no-select:focus-visible {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/table.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,iBAAA,EAAA,kCAAA;EACA,gBAAA;EAEA,4BAAA;AAAF;AACE;EACE,UAAA,EAAA,oCAAA;AACJ;AAEE;EACE,mBAAA,EAAA,sCAAA;AAAJ;AAGE;EACE,mBAAA,EAAA,iCAAA;EACA,kBAAA,EAAA,kCAAA;AADJ;AAIE;EACE,gBAAA,EAAA,0CAAA;AAFJ;;AAMA;EACE,WAAA;EACA,yBAAA;EACA,sBAAA;AAHF;;AAMA;EACE,wBAAA;EAAA,gBAAA;EACA,MAAA;EACA,iBAAA;EACA,WAAA;AAHF;;AAMA;EACE,YAAA;EACA,WAAA;EACA,mDAAA;EACA,kBAAA;EACA,aAAA;AAHF;;AAMA;EACE,yBAAA;EAGA,iBAAA;AAHF;;AAMA;EACE,aAAA;AAHF","sourcesContent":["table tr td {\n  border: 1px solid gray;\n}\n\n.table_wrapper {\n  max-height: 500px; /* Set your desired fixed height */\n  overflow-y: auto;\n\n  /* Custom scrollbar styles */\n  &::-webkit-scrollbar {\n    width: 6px; /* Width of the vertical scrollbar */\n  }\n\n  &::-webkit-scrollbar-track {\n    background: #f1f1f1; /* Background of the scrollbar track */\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #6c0cdc; /* Color of the scrollbar thumb */\n    border-radius: 6px; /* Rounded corners for the thumb */\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background: #555; /* Color of the scrollbar thumb on hover */\n  }\n}\n\n.table_container {\n  width: 100%;\n  border-collapse: separate;\n  border: 1px solid gray;\n}\n\n.table_container thead th {\n  position: sticky;\n  top: 0;\n  background: white;\n  z-index: 10;\n}\n\n.fade-bg {\n  height: 100%;\n  width: 100%;\n  background-color: #f1f1f1d1;\n  position: absolute;\n  z-index: 9999;\n}\n\n.no-select {\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.no-select:focus-visible {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
