import forge from "node-forge";
import RSA_KEY from "./RSA";
import { store } from "../redux/store";
import { setUser } from "../redux/actions/auth";
export const decryptCrypto = async (keys) => {
  const { encryptedAESKey, userData, iv } = keys;

  // Function to decrypt AES key with RSA using node-forge
  function decryptAESKeyWithRSA(encryptedAESKey, privateKey) {
    const privateKeyForge = forge.pki.privateKeyFromPem(privateKey);
    const encryptedBuffer = forge.util.decode64(encryptedAESKey);
    const decrypted = privateKeyForge.decrypt(encryptedBuffer, "RSA-OAEP");
    return decrypted;
  }

  // Function to decrypt data with AES
  function decryptWithAES(encryptedData, key, iv) {
    const decipher = forge.cipher.createDecipher("AES-CBC", key);
    decipher.start({ iv: forge.util.decode64(iv) });
    decipher.update(
      forge.util.createBuffer(forge.util.decode64(encryptedData))
    );
    decipher.finish();
    const decrypted = decipher.output.toString("utf8");
    return decrypted;
  }

  if (encryptedAESKey && userData && iv) {
    try {
      const decryptedAESKey = decryptAESKeyWithRSA(encryptedAESKey, RSA_KEY);
      const decryptedData = decryptWithAES(userData, decryptedAESKey, iv);
      const parsedData = JSON.parse(decryptedData);
      store.dispatch(setUser(parsedData));
      return parsedData;
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  } else {
    console.error("Missing encryption data");
  }
};
