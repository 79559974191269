import axiosInstance from "./axiosInstance";

const loginService = (email, password) => {
  return axiosInstance.Auth.post(
    process.env.REACT_APP_API_URL + "user/signin",
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
};

const logoutAction = () => {
  return axiosInstance.Auth.get(
    process.env.REACT_APP_API_URL + "user/logout",

    {
      withCredentials: true,
    }
  );
};
const forgotPassword = (email) => {
  return axiosInstance.Auth.post(
    process.env.REACT_APP_API_URL + "forgotPassword",
    {
      email,
    },
    {
      withCredentials: true,
    }
  );
};
export const resetPassword = (payload) => {
  return axiosInstance.Auth.post(
    process.env.REACT_APP_API_URL + "resetPassword",
    payload,
    {
      withCredentials: true,
    }
  );
};

export { loginService, logoutAction, forgotPassword };
