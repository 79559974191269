import React, { Fragment, useState, useRef } from "react";
import { FaBell } from "react-icons/fa";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
} from "reactstrap";
import bg4 from "../../../assets/utils/images/dropdown-header/abstract4.jpg";
import TimelineEx from "./Notification-timeline";
import socket from "../../../socket";
// import notifications from "./db";

const HeaderDots = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleViewAllClick = () => {
    setDropdownOpen(false);
  };
  React.useEffect(() => {
    // Function to handle initial notifications
    const handleInitialNotifications = (initialNotifications) => {
      setNotifications(initialNotifications);
    };

    // Function to handle unread count update
    const handleUnreadCount = (count) => {
      setUnreadCount(count);
    };

    // Function to handle notification update
    const handleNotificationUpdated = (updatedNotification) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) =>
            notification.id !== updatedNotification.id ||
            !updatedNotification.isRead
        )
      );

      setUnreadCount((prevCount) =>
        updatedNotification.isRead ? prevCount - 1 : prevCount
      );
    };
    // Function to handle new notification
    const handleNewNotification = (newNotification) => {
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
      setUnreadCount((prevCount) => prevCount + 1);
    };

    // Event listeners for socket events
    socket.on("initialNotifications", handleInitialNotifications);
    socket.on("unreadCount", handleUnreadCount);
    socket.on("notificationUpdated", handleNotificationUpdated);
    socket.on("newNotification", handleNewNotification);

    // Request initial data on connection
    socket.on("connect", () => {
      socket.emit("getNotificationsCount");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Function to handle marking notification as read
  const handleMarkNotificationRead = (notificationId, readStatus) => {
    socket.emit("notificationUpdated", notificationId, readStatus);
  };

  // const sendNotifications = () => {
  //   socket.emit("newNotification", " New notification received");
  //   console.log("notification sent");
  // };

  return (
    <Fragment>
      {/* <button onClick={sendNotifications}> send</button> */}
      <div className="header-dots">
        <UncontrolledDropdown
          disabled={unreadCount === 0}
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          ref={dropdownRef}
        >
          <DropdownToggle className="p-0 me-2" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-primary" />
              <FaBell
                color="#3f6ad8"
                fontSize="23px"
                style={{ position: "relative" }}
              />{" "}
              {unreadCount !== 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    width: "25px",
                    height: "25px",
                    padding: "2px",
                    right: "-10px",
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "100%",
                  }}
                >
                  {unreadCount}
                </span>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xl rm-pointers">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-plum-plate">
                <div
                  className="menu-header-image"
                  style={{ backgroundImage: `url(${bg4})` }}
                />
                <div className="menu-header-content text-white">
                  <h5 className="menu-header-title">Notifications</h5>
                  <h6 className="menu-header-subtitle">
                    You have {unreadCount} unread Notification
                  </h6>
                </div>
              </div>
            </div>
            <div className="grid-menu grid-menu-xl grid-menu-3col">
              <TimelineEx
                notifications={[...notifications].reverse()}
                handleMarkNotificationRead={handleMarkNotificationRead}
              />
            </div>
            <Nav vertical>
              <NavItem className="nav-item-divider" />
              <NavItem className="nav-item-btn text-center">
                <Button
                  size="sm"
                  className="btn-shadow"
                  color="primary"
                  onClick={handleViewAllClick}
                >
                  Close
                </Button>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Fragment>
  );
};

export default HeaderDots;
