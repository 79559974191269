const RSA_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIEogIBAAKCAQEAhj0datVD7UEi+GzU8nCOgZtkJ5Obc0Wp7ULa/WGP1On/DniI
dJWLPOgZ7oFV9QcCNpbmV7IEh4ARfq8MDsuK8i22ngYhDc79tIb4u/mFi1PxFMyn
TkxKrK4+Ko5kcMQmofqtK6b0FMNK1gU2w8ZbdNmDrUV9VlOUP0+DVRRYY9vUNZY9
QoUr8QM2jEJHhpGE3YamTbV+NfLipG6/rbRrL81Xt93+kjyXhlDmr5pKLWAYVTnj
wjnr++E5i03s+BsMCtZsWzBg24LUN34giuFeD639SyqLFTLc/CecL7RFhEpbk1uG
fBij74vZYQ0RWuMdzgGq8OpcZwHNZRPBFEj6lwIDAQABAoIBAGVE+8lZxFnbVud1
Kx0O/uMV+HPG7nIC7xPX6N9YV6q3MLyY/9B79MPpEY8VsWQdenT2WCV2XeZfHlOk
+QK8WUEkbW0/kHYbXthvLnyY3D9k/y3CysDN21P0S1/NdkaAI+j09OlGlUKIpg9Z
dZwyK1K2uZjhIJIckW1Frf1kUN5bnz+JL6WKiWj5WIsVUdd+5pUCCDW0VPz5tTmB
+sO35kpqWmwvPrpKDXdG/q978MriDM/qI8j1OokXbcsQxzm3k2hkz8khNS/66VFd
OkDFnvtSmp/fl18SVYUFrjv+293cylQKzZWbazRgP9n/VQ3WF4XFGAWzRpdcoPue
PqHdTHECgYEA3/ZkejCHet6LUzkIR8JQEhfb+cFZy5dLvzpxbMvWpHsWO5Rga8VV
unw3NuLYwNz/qryReRx+17RSvWlS0Pcq9acKkiTsfHwoL8vVnpeiK6ggwh0s8K+c
BJE2wJPVZsvKs42N4Jfla24KEhFx33GYa4+NuXVzfMP5yv9k8mYu198CgYEAmXD/
jRJM+x+oXFcq6pG903RDDZAEItQDOd6Rk16JPJwojEX8wiTtU6MlX5eDgk8nzznA
qowVqfCG3Rdg+PUHnCZ/0xO/2rGr8FJggC3pugzJEoKSAVxjwIkUjt0nJhwU4yUJ
INvlDRXN9FjSaWWMzO53hGFLM6Zj/iZpvPnwFEkCgYAQftpZsZdY8mk6oPm+J060
dnNqStZtS2B13j0acU0G4lX532zWozs0OA/tB/pFAkIGdksUq4egJZTuhIJoB7xv
SXVa3ENay9493vqF6s8z/fcjtPhpZdXM9FEfEARoxNvSK7vmbemk5v7w474KYhiC
25jprv/pkZmlXPg3blVG6wKBgBBYz4DyAC2I0+DykGnpGPYcHsClJSuotqdFpPC0
U56XbGJ1WlY8pOQSL3sdoy8Qy8bx+iqxPeWa8GNBiF09jY//MgMEyZ286/pwbEN7
oLjnf8xvCKsIcsAiWoRV30PaHgeM/In5o09fkU+E3vXHkdaLWURLMT3Rbjm3UfCG
GCOxAoGAE/M+zkGCzM6ehp89TGFMLe4InirZP0pGryFUcL0UQRa0U1K3OQ5fYb3q
2Uah+MIDKg3ibMGyTjrzaPzEWgPS3f44/jZMAJq4hO0+yX8mmMkyJGjomjGYV+JR
rn6miiKXaIWmOGmWBgkyr/JSZigzHyAzk7XQEO7IU/NNElMwuVA=
-----END RSA PRIVATE KEY-----`;
export default RSA_KEY;
