// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_popup__y1Mx9 {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
}
.styles_popup__y1Mx9 svg {
  position: absolute;
  top: 2rem;
  right: 2rem;
  fill: #fff;
  cursor: pointer;
  font-size: 20px;
  z-index: 100;
}
.styles_popup__y1Mx9 img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #fff;
  border-radius: 5px;
  width: 750px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .styles_popup__y1Mx9 img {
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Common/ImageBackdrop/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,YAAA;EACA,WAAA;AACF;AAEE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AADJ;AAGI;EAVF;IAWI,UAAA;EAAJ;AACF","sourcesContent":[".popup {\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.9);\n  height: 100%;\n  width: 100%;\n  // display: none;\n\n  svg {\n    position: absolute;\n    top: 2rem;\n    right: 2rem;\n    fill: #fff;\n    cursor: pointer;\n    font-size: 20px;\n    z-index: 100;\n  }\n\n  img {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border: 5px solid #fff;\n    border-radius: 5px;\n    width: 750px;\n    object-fit: cover;\n\n    @media (max-width: 768px) {\n      width: 95%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `styles_popup__y1Mx9`
};
export default ___CSS_LOADER_EXPORT___;
