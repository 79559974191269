import React from "react";
import notifications from "../Layout/AppHeader/db";
import Tabs from "react-responsive-tabs";
import tick from "../../assets/images/icons8-circle-48.png";
import unTick from "../../assets/images/icons8-circle-48 (1).png";

const Notifications = () => {
  const tabsContent = [
    {
      title: "Unread",
      content: (
        <div>
          {notifications
            .filter((item) => item.read === false)
            .map((item) => {
              return (
                <div key={item.id} className="">
                  <div className="notification-title">
                    <h6>
                      <img
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "5px",
                        }}
                        src={tick}
                        alt=""
                      />
                      {item.title}
                    </h6>
                    <div className="badge bg-secondary badge-not ms-2">
                      mark as read
                    </div>
                  </div>

                  <p className="notification-message">{item.message}</p>
                </div>
              );
            })}
        </div>
      ),
    },
    {
      title: "Read",
      content: (
        <div>
          {notifications
            .filter((item) => item.read === true)
            .map((item) => {
              return (
                <div key={item.id} className="">
                  <div className="notification-title">
                    <h6>
                      <img
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "5px",
                        }}
                        src={unTick}
                        alt=""
                      />
                      {item.title}
                    </h6>
                    <div className="badge bg-secondary badge-not ms-2">
                      mark as unread
                    </div>
                  </div>

                  <p className="notification-message">{item.message}</p>
                </div>
              );
            })}
        </div>
      ),
    },
  ];

  function getTabs() {
    return tabsContent.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  return (
    <React.Fragment>
      <div className="backbtn">
        <Tabs
          tabsWrapperClass="body-tabs body-tabs-alt"
          transform={false}
          showInkBar={true}
          items={getTabs()}
        />
      </div>
    </React.Fragment>
  );
};

export default Notifications;
