// import axios from "axios";
// import { authHeader } from "./auth-header";
import axiosInstance from "./axiosInstance";

const getAllTrackChanges = (publicId, search, page, perPage, active) => {
  return axiosInstance.Auth.get(
    process.env.REACT_APP_API_URL +
      `changeLog/list/${publicId}?all=true&keyword=${search}&page=${page}&perPage=${perPage}&isActive=${active}`
  );
};
const downloadTrackChanges = (publicId) => {
  return axiosInstance.Auth.get(
    process.env.REACT_APP_API_URL + `changeLog/download/${publicId}`,
    {
      responseType: "blob",
    }
  );
};
export const trackChange = { getAllTrackChanges, downloadTrackChanges };
