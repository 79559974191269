import React from "react";
import Loader from "react-loaders";
import { Card } from "reactstrap";

const LoaderComponent = () => {
  return (
    <Card className="main-card mb-3">
      <div
        className="loader-container"
        style={{ width: "75vw", height: "75vh" }}
      >
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-spin-fade-loader" />
          </div>
          <h6 className="mt-5">Please wait...</h6>
        </div>
      </div>
    </Card>
  );
};

export default LoaderComponent;
