export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_USER = "SET_USER";
export const SET_TEST_PERMISSION = "SET_TEST_PERMISSION";
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const CLEAR_USER = "CLEAR_USER";
export const SET_ROLE_ID = "SET_ROLE_ID";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SHOW_IMAGE_BACKDROP = "SHOW_IMAGE_BACKDROP";
export const CLOSE_IMAGE_BACKDROP = "CLOSE_IMAGE_BACKDROP";

export const RETRIEVE_ACCOUNT = "RETRIEVE_ACCOUNT";

// Taxpro Operations
export const TAXPRO_USERLISTSUCCESS = "TAXPRO_USERLISTSUCCESS";
export const TAXPRO_USERLIST_FAILURE = "TAXPRO_USERLIST_FAILURE";

export const TAXPRO_USERLIST_DETAILS = "TAXPRO_USERLIST_DETAILS";

//Account Operation
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const RETRIEVE_ACCOUNT_REQUEST = "RETRIEVE_ACCOUNT_REQUEST";
export const RETRIEVE_ACCOUNT_SUCCESS = "RETRIEVE_ACCOUNT_SUCCESS";
export const RETRIEVE_ACCOUNT_FAILURE = "RETRIEVE_ACCOUNT_FAILURE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR ";

export const RETRIEVE_TRANSCRIPTREQUEST = "RETRIEVE_TRANSCRIPTREQUEST";

export const ADD_TRANSCRIPTREQUEST_LOCAL = "ADD_TRANSCRIPTREQUEST_LOCAL";
export const EXTRACTALLTRANSCRIPT = "EXTRACTALLTRANSCRIPT";
export const MAILBOX_REQUEST = "MAILBOX_REQUEST";
export const DELETE_COMBINED_TRANSCRIPT = "DELETE_COMBINED_TRANSCRIPT";
export const CLEARTRANSCRIPT = "CLEARTRANSCRIPT";
export const UPDATE_TRANSCRIPT = "UPDATE_TRANSCRIPT";
export const RETRIEVE_TRANSCRIPT_REQUEST = "RETRIEVE_TRANSCRIPT_REQUEST";
export const RETRIEVE_TRANSCRIPT_SUCCESS = "RETRIEVE_TRANSCRIPT_SUCCESS";
export const RETRIEVE_TRANSCRIPT_FAILURE = "RETRIEVE_TRANSCRIPT_FAILURE";

//  TrackChanges Operations

export const TRACKCHANGES_REQUEST = "TRACKCHANGES_REQUEST";
export const TRACKCHANGES_SUCCESS = "TRACKCHANGES_SUCCESS";
export const TRACKCHANGES_FAILURE = "TRACKCHANGES_FAILURE";

export const TRACKCHANGES_DOWNLOAD_REQUEST = "TRACKCHANGES_DOWNLOAD_REQUEST";
export const TRACKCHANGES_DOWNLOAD_SUCCESS = "TRACKCHANGES_DOWNLOAD_SUCCESS";
export const TRACKCHANGES_DOWNLOAD_FAILURE = "TRACKCHANGES_DOWNLOAD_FAILURE";
