/* eslint-disable no-unused-vars */

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_MESSAGE,
  SET_USER,
  CLEAR_USER,
} from "./types";

import { loginService } from "../services/auth.service";

export const login = (email, password, rememberMe) => (dispatch) => {
  return loginService(email, password, rememberMe).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

// export const decryptAndSetRoleId = (testPermission) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const { encryptedAESKey, userData, iv } = testPermission;
//     console.log(encryptedAESKey, userData, iv);

//     function decryptAESKeyWithRSA(encryptedAESKey, privateKEY) {
//       return crypto
//         .privateDecrypt(
//           {
//             key: privateKEY,
//             padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
//           },
//           Buffer.from(encryptedAESKey, "base64")
//         )
//         .toString("base64");
//     }

//     async function decryptWithAES(userData, key, iv) {
//       const decipher = crypto.createDecipheriv(
//         "aes-256-cbc",
//         key,
//         Buffer.from(iv, "base64")
//       );
//       let decrypted = decipher.update(userData, "base64", "utf8");
//       decrypted += decipher.final("utf8");
//       return JSON.parse(decrypted);
//     }

//     if (encryptedAESKey) {
//       try {
//         const decryptedAESKey = decryptAESKeyWithRSA(encryptedAESKey, RSA_KEY);
//         const key = Buffer.from(decryptedAESKey, "base64");
//         const decryptedData = await decryptWithAES(userData, key, iv);
//         console.log(decryptedData);
//         if (decryptedData.length > 0) {
//           dispatch(setRoleId(decryptedData[0]?.roleId));
//         }
//       } catch (error) {
//         console.error("Error decrypting data:", error);
//       }
//     }
//   };
// };
