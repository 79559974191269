import React from "react";
import { Row, Col, Container, CardBody, Button } from "reactstrap";

import DataTable from "react-data-table-component";

import PageContainer from "../../Layout/PageContainer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounceFunction from "../../../helpers/Debounce";
import FilterComponent from "../../../helpers/FilterComponent";

import LoaderComponent from "../../Common/Loader";
import { trackChangeAction } from "../../../redux/actions/trackChange.acion";
import { downloadFromUrlCSV } from "../../../utils/downloadFromblob";

toast.configure();

const TrackChanges = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = React.useState(50);

  const {
    changeLogList: filteredData,
    loading,
    hasMore,
  } = useSelector((state) => state.trackChange);
  const [filterText, setFilterText] = React.useState("");
  const [loadings, setLoading] = React.useState({
    isLoading: false,
    isLoading2: false,
  });

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  React.useEffect(() => {
    if (id) {
      setLoading({ isLoading: true });
      dispatch(trackChangeAction(id, filterText, 1, perPage, 1, 0));
      setLoading({ isLoading: false });
    }
  }, [filterText, id, dispatch, perPage]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 100 ||
      !hasMore ||
      loading
    ) {
      return;
    }
    setPerPage((pre) => pre + 50);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);
  const columns = React.useMemo(
    () => [
      {
        name: "Change Date",
        selector: (row) =>
          row.changeDate
            ? new Date(row.changeDate).toLocaleDateString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                //   hour: "2-digit",
                //   minute: "2-digit",
                //   hour12: false,
              })
            : "",
        sortable: true,
        width: "125px",
      },
      {
        name: "Tax Pro",
        selector: (row) => row?.Account?.TaxPro?.name,
        sortable: true,
        width: "100px",
      },
      {
        name: "Account Type",
        selector: (row) => (row?.type === 0 ? "Individual" : "Business"),
        sortable: true,
        width: "150px",
      },
      {
        name: "SSN/EIN",
        selector: (row) => row?.Account.taxId,
        sortable: true,
        width: "150px",
      },
      {
        name: "First Name",
        selector: (row) => row?.Account?.firstName,
        sortable: true,
        width: "120px",
      },
      {
        name: "Last Name",
        selector: (row) => row?.Account?.lastName,
        sortable: true,
        width: "120px",
      },

      {
        name: "Business Name",
        selector: (row) => row?.Account?.legalBusinessName,
        sortable: true,
      },

      {
        name: "Tax Year",
        selector: (row) => row?.taxYear,
        sortable: true,
      },
      {
        name: "Tax Period",
        selector: (row) => ` ${row?.taxPeriod ? `Q ${row?.taxPeriod}` : ""}`,
        sortable: true,
      },
      {
        name: "Field Changed",
        selector: (row) => row?.fieldDescription,
        sortable: true,
        width: "150px",
      },
      {
        name: "Previous Value",
        selector: (row) => row?.previousValue,
        sortable: true,
      },
      {
        name: "New Value",
        selector: (row) => row?.newValue,
        sortable: true,
      },
    ],
    []
  );

  const handleDownloadCsv = async () => {
    setLoading({ isLoading2: true });
    await downloadFromUrlCSV(id, "changeLog/download", "");
    setLoading({ isLoading2: false });
  };

  // Search
  const debounceSearch = React.useMemo(
    () =>
      debounceFunction((nextValue) => {
        dispatch(trackChangeAction(id, nextValue, 1, perPage, 1, 0));
      }, 1000),
    [dispatch, id]
  );

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setLoading({ isLoading2: true });
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        dispatch(trackChangeAction(id, "", 1, perPage, 1, 0));
        setLoading({ isLoading2: false });
      }
    };

    return (
      <FilterComponent
        width="100%"
        onFilter={(event) => {
          setFilterText(event.target.value);
          debounceSearch(event.target.value);
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle, dispatch, id, debounceSearch]);

  return (
    <PageContainer
      pageTitleIcon="pe-7s-graph icon-gradient bg-plum-plate"
      pageHeading="Track Changes"
      pageSubTitle="Tracking all the changes in the account"
    >
      <Container fluid>
        <Row
          className="align-items-center mb-3 justify-content-between"
          style={{ width: "100%", margin: "auto" }}
        >
          <Col md="6" className="d-flex gap-2">
            <Button
              color="primary"
              disabled={filteredData.length === 0}
              onClick={handleDownloadCsv}
            >
              Export
            </Button>
          </Col>
          <Col md="4">{subHeaderComponent}</Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col md="12">
            <CardBody>
              {loadings.isLoading2 ? (
                <LoaderComponent />
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  paginationServer
                  subHeaderComponent={subHeaderComponent}
                  // progressPending={loading}
                  progressComponent={<LoaderComponent />}
                />
              )}
              <h5>{loading ? "Loading Data..." : ""}</h5>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default TrackChanges;
