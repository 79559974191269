import { downloadFromUrlCSV } from "../../utils/downloadFromblob";
import { trackChange } from "../services/trackChange.service";
import {
  TRACKCHANGES_REQUEST,
  TRACKCHANGES_SUCCESS,
  TRACKCHANGES_FAILURE,
  TRACKCHANGES_DOWNLOAD_REQUEST,
  TRACKCHANGES_DOWNLOAD_SUCCESS,
  TRACKCHANGES_DOWNLOAD_FAILURE,
} from "./types";

export const trackChangeAction =
  (publicId, search, page, perPage, active) => async (dispatch) => {
    dispatch({ type: TRACKCHANGES_REQUEST });
    try {
      const res = await trackChange.getAllTrackChanges(
        publicId,
        search,
        page,
        perPage,
        active
      );
      dispatch({
        type: TRACKCHANGES_SUCCESS,
        payload: res.data.changeLogList,
      });
    } catch (err) {
      dispatch({
        type: TRACKCHANGES_FAILURE,
        payload: err.message || "Something went wrong",
      });
    }
  };

export const trackChangeDownload = (publicId) => async (dispatch) => {
  dispatch({ type: TRACKCHANGES_DOWNLOAD_REQUEST });
  try {
    const res = await downloadFromUrlCSV(publicId, "changeLog/download");
    dispatch({
      type: TRACKCHANGES_DOWNLOAD_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: TRACKCHANGES_DOWNLOAD_FAILURE,
      payload: err.message || "Something went wrong",
    });
    return Promise.reject(err);
  }
};
