/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from "react";

import {} from // VerticalTimeline,
// VerticalTimelineElement,
"react-vertical-timeline-component";
import parse from "html-react-parser";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as Ionicons from "react-icons/io";
import IconContainer from "../../Common/IconContainer";
const TimelineEx = ({ notifications, handleMarkNotificationRead }) => {
  // eslint-disable-next-line import/namespace
  const ActiveIcon = Ionicons["IoIosCheckmarkCircleOutline"];

  return (
    <Fragment>
      <div className="scroll-area-sm">
        <PerfectScrollbar>
          <div className="">
            <div className="notifications-box">
              {/* <VerticalTimeline
                animate={false}
                layout="1-column"
                className="vertical-time-simple vertical-without-time"
              > */}
              <section>
                {notifications.map((item) => {
                  const parsedContent = item.title ? parse(item.title) : null;
                  return (
                    <div
                      className={`vertical-timeline-item ${item.isRead === 1 ? "dot-success" : "dot-warning"} `}
                      key={item.id}
                      style={{
                        marginTop: "0.375rem",
                      }}
                    >
                      <div className=" box-title">
                        <div
                          className="badge  ms-2"
                          onClick={() => handleMarkNotificationRead(item.id, 1)}
                        >
                          <IconContainer
                            id="Mark as read"
                            Icon={ActiveIcon}
                            iconColor={"#3ac47d"}
                            text={"Mark as read"}
                          />
                        </div>
                        <h6 className="not-title">{parsedContent}</h6>
                      </div>
                    </div>
                  );
                })}
              </section>
              {/* </VerticalTimeline> */}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </Fragment>
  );
};

export default TimelineEx;
