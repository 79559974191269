export const AdminNav = [
  {
    icon: "pe-7s-note2",
    label: "Accounts",
    content: [
      {
        label: "Add",
        to: "/account/add",
      },
      {
        label: "List",
        to: "/account/list",
      },
    ],
  },

  {
    icon: "pe-7s-add-user",
    label: "TaxPros",
    content: [
      {
        label: "Add",
        to: "/taxpros/add",
      },
      {
        label: "List",
        to: "/taxpros/list",
      },
    ],
  },
];
