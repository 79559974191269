import {
  CREATE_ACCOUNT,
  RETRIEVE_ACCOUNT_SUCCESS,
  RETRIEVE_ACCOUNT_FAILURE,
  RETRIEVE_ACCOUNT_REQUEST,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_ERROR,
} from "../actions/types";

const initialState = {
  users: [],
  totalUserCount: 0,
  loading: false,
  error: null,
  hasMore: true,
  accountDetails: {},
};

const AccountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  // Log the action and payload to inspect the structure

  switch (type) {
    case RETRIEVE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RETRIEVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload?.rows || [], // Use optional chaining and default to empty array
        totalUserCount: payload?.count || 0,
        hasMore: payload?.count > state.users.length,
      };
    case RETRIEVE_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CREATE_ACCOUNT:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
        error: null,
      };
    case UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AccountReducer;
