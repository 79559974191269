import axios from "axios";
import { authHeader, pdfHeader } from "./auth-header";
import { clearUser } from "../actions/auth";
import { store } from "../store";
import { decryptCrypto } from "../../utils/decryptCrypto";
import { clearSession } from "../actions/session";

const axiosInstanceAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Ensure credentials are sent
  headers: authHeader(), // Set default headers
});

const axiosInstancePdf = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Ensure credentials are sent
  headers: pdfHeader(),
});

let isRefreshing = false;
let subscribers = [];

const refreshToken = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "user/refreshSession",
      {
        withCredentials: true, // Ensure credentials are sent
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to refresh token");
    }
    const keys = {
      encryptedAESKey: response.data.data.encryptedAESKey,
      userData: response.data.data.userData,
      iv: response.data.data.iv,
    };
    decryptCrypto(keys);
    window.location.reload();
    return response.data; // Adjust this based on your API response structure
  } catch (error) {
    store.dispatch(clearSession());
    store.dispatch(clearUser());
    window.location.href = "/login";
    throw error;
  }
};

const onTokenRefreshed = () => {
  subscribers.forEach((callback) => callback());
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

axiosInstanceAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await refreshToken();
          isRefreshing = false;
          onTokenRefreshed();
        } catch (e) {
          return Promise.reject(e);
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(() => {
          originalRequest.headers = authHeader(); // Update the headers with the new token
          resolve(axiosInstanceAuth(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

const axiosInstance = {
  Auth: axiosInstanceAuth,
  Pdf: axiosInstancePdf,
};

export default axiosInstance;
