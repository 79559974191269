const notifications = [
  {
    id: 1,
    title: "New Message",
    message: "You have received a new message from John Doe.",
    type: "message",
    timestamp: "2024-06-25T14:48:00.000Z",
    read: false,
  },
  {
    id: 2,
    title: "Friend Request",
    message: "Jane Smith has sent you a friend request.",
    type: "friend_request",
    timestamp: "2024-06-25T13:22:00.000Z",
    read: false,
  },
  {
    id: 3,
    title: "Event Reminder",
    message: "Don't forget about the meeting tomorrow at 10 AM.",
    type: "reminder",
    timestamp: "2024-06-24T08:15:00.000Z",
    read: true,
  },
  {
    id: 4,
    title: "Promotion",
    message: "Get 20% off on your next purchase using the code SAVE20.",
    type: "promotion",
    timestamp: "2024-06-23T10:30:00.000Z",
    read: true,
  },
  {
    id: 5,
    title: "System Alert",
    message: "Your password will expire in 3 days. Please update it soon.",
    type: "alert",
    timestamp: "2024-06-22T11:45:00.000Z",
    read: false,
  },
  {
    id: 6,
    title: "Comment",
    message: "Alice commented on your post: 'Great job!'",
    type: "comment",
    timestamp: "2024-06-21T09:25:00.000Z",
    read: true,
  },
  {
    id: 7,
    title: "Like",
    message: "Bob liked your photo.",
    type: "like",
    timestamp: "2024-06-20T15:40:00.000Z",
    read: false,
  },
];

export default notifications;
